<template>
  <div>
    <v-container> </v-container>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>
